.mask {
  color: rgb(0, 0, 0);
  mix-blend-mode: screen;
}
.bestSeller {
  background: #fff;
  color: #0b2d71;
  position: absolute;
  display: block;
  top: 30px;
  right: -30px;
  width: 128px;
  padding: 9px 20px 8px;
  margin: 0;
  transform: rotate(45deg);
  height: 32px;
  z-index: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.planCard {
  flex-grow: 1;
  flex-basis: 320px;
  background: #fff;
  min-width: 160px;
  max-width: 320px;
  text-align: center;
  position: relative;

  z-index: 1;
}
.planFeatured {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
  z-index: 3;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}
.ribbonContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 120px;
  overflow: hidden;
}

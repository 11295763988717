/* slidertext background colors */
.darkPurple {
  background-color: rgba(58, 13, 54, 0.5);
}
.darkTeal {
  background-color: rgba(0, 54, 83, 0.5);
}
.darkBlue {
  background-color: rgba(11, 45, 113, 0.5);
}
.lightGray {
  background-color: rgba(151, 157, 163, 0.5);
}

.slideControl {
  backdrop-filter: blur(40px) opacity(1);
}
.slideControlBg {
  background-color: rgba(7, 5, 7, 0.5);
}
.progressBarBg {
  background-image: linear-gradient(to right, #0066b2, #0066b2);
}
.slideContainer {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* transform: translateX(-100%);
  -webkit-transform: translateX(-100%); */
  filter: brightness(80%);
  opacity: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  object-fit: cover;
  background-position: right top;
}
.slideContainerSlideIn {
  animation: slideContainerSlideIn 200ms forwards;
  opacity: 1;
}
/* @keyframes slideContainerSlideIn {
  100% {
    transform: translateX(0%);
  }
} */
.zoomSlideContainerBg {
  animation: zoomSlideContainerBg 20s 2;
  color: rgb(12, 9, 9);
}
@keyframes zoomSlideContainerBg {
  50% {
    background-position: center;
  }
}
.cz-20 {
  z-index: 20;
}
.backgroundGradient {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6) 0,
    rgba(0, 0, 0, 0) 200px
  );
}

.sliderTextContainer {
  opacity: 1;
  z-index: 3;
  pointer-events: all;
  transition: transform 0.75s cubic-bezier(0.72, 0.05, 0.35, 1) 0.75s,
    opacity 0.5s cubic-bezier(0.72, 0.05, 0.35, 1) 0.75s;
  backdrop-filter: blur(40px) opacity(1);
  transform: translateX(-100%);
  filter: brightness(100%);
}

.sliderTextContainerSlideIn {
  animation: sliderTextContainerSlideIn 2s forwards;
  -webkit-animation: sliderTextContainerSlideIn 2s forwards;
}

.sliderTextContainerSlideOut {
  animation: sliderTextContainerSlideOut 0.5s ease-in-out;
}

@keyframes sliderTextContainerSlideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes sliderTextContainerSlideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
.videSlider {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=DM+Serif+Display:ital@0;1&family=Protest+Guerrilla&display=swap");
.whoweareDarkBlue {
  background-color: rgba(11, 45, 113);
}
.spacer {
  height: 10vh;
  background-color: rgba(11, 45, 113);
  width: 100%;
  /* overflow: hidden; */
  position: relative;
  z-index: 0;
  bottom: 0;
}

.animateTextScrollParent {
  transition: 2s ease-in-out all;
  text-align: center;
  list-style: none;
  display: block;
  margin-bottom: 8px;
}
.imageContainer {
  transition: 0.5s ease-in-out all;
}
.background-text__item {
  font-style: normal;

  font-weight: 800;
  letter-spacing: -0.013em;

  text-align: center;
  background: transparent;
  z-index: -1111;
}
.lightBlue {
  color: #009dd9;
}
.mediumBlue {
  color: #0066b2;
}
.daarkBlue {
  color: #163f8f;
}
.protest-guerrilla-regular {
  font-family: "Protest Guerrilla", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.description {
  letter-spacing: -0.006em;
}

#descript .changeOpacity,
.imageContainer div {
  width: 100%;
  opacity: 0;
  position: relative;
  z-index: 9999;
}

@media screen and (min-width: 992px) {
  .swiper {
    width: 100%;
    height: auto !important;
    position: initial !important;
  }
  .swiper-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #0b2d71;
    height: 346px !important;
    width: 282px !important;
    border-radius: 10px;
    color: whitesmoke;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper {
    width: 100%;
    height: 300px;
    margin: 20px auto;
  }
  .swiper-slide.swiper-slide-active {
    background-color: #0b2d71;
    transition: 500ms ease;
    width: 384px !important;
    height: 472px !important;
  }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
    transition: 500ms ease;
  }
  .swiper-pagination-bullet-active {
    cursor: pointer;
    width: 25px;
    border-radius: 10px;
    height: 5px;
    background-color: black !important;
  }
  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767.99px) {
  .swiper {
    width: 240px;
    height: 320px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;

    background: #0b2d71;
    color: #fff;
  }
}

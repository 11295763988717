@font-face {
  font-family: gothanBlack;
  src: url(./fonts/gothamnarrow/GothamNarrow-Black.otf);
}
@font-face {
  font-family: gothanBold;
  src: url(./fonts/gothamnarrow/GothamNarrow-Bold.otf);
}
@font-face {
  font-family: gothanNarrowBook;
  src: url(./fonts/gothamnarrow/GothamNarrow-Book.otf);
}

.gothanNarrowBook {
  font-family: gothanNarrowBook;
}
.gothanBlack {
  font-family: gothanBlack;
}

.gothanBold {
  font-family: gothanBold;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  /* font-family: "DM Sans", sans-serif;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; */

  outline: 0;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
.link:hover {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  );
}
.underlines {
  text-decoration: none;

  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 0 1px;
  transition: background-size 0.3s cubic-bezier(0.72, 0.05, 0.35, 1);
  padding-bottom: 2px;
}
.goog-te-gadget-icon {
  display: none;
}

.goog-te-gadget-simple {
  background-color: black !important;
  border: 0 !important;
  color: white !important;
  font-size: 10pt;
  font-weight: 800;
  height: 20px;
  display: inline-block;
  padding: 2px 10px !important;
  margin-left: 15px;
  border-radius: 20px;
  cursor: pointer;
  zoom: 1;
  position: fixed;
  bottom: 10px;
  opacity: 0.2;
  font-size: 10px;
  z-index: 9999;
}

.goog-te-gadget-simple span {
  color: white !important;
}
#\:0\.targetLanguage > span > a > span:nth-child(5) {
  display: none;
}
#\:0\.targetLanguage > span > a > span:nth-child(3) {
  display: none;
}
[css].goog-te-banner-frame {
  visibility: hidden !important;
}
